import React from 'react'


const NotFound = () => {

  return (
    <>
     <div>Not found</div>
    </>
  )
}

export default NotFound