import React from 'react'

import {Footer} from '../components/Footer/Footer'
import { Projects } from '../components/Projects/Projects' 
import ContactForm from '../components/ContactSection/ContactForm'


const Portfolio = () => {

  return (
    <>
      <Projects/>
      <ContactForm />
    </>
  )
}

export default Portfolio